<template>
  <div class="passIdDashboard">
    <div class="passIdDashboard__section js-acc-item-mobile">
      <div class="passIdDashboard__title js-acc-title-mobile" @click="toggleContent">
        <span class="text">Drivers license</span>
        <span class="icon"><SvgIcon name="down-arrow"/></span>
      </div>
      <div class="passIdDashboard__form js-acc-content-mobile">
        <!-- add class "uploadedDoc" if documents have already been uploaded -->
        <form
          class="form form--passId js-ajaxForm whitePicker js-wrap-picker-license"
          :class="[{'uploadedDoc' : licensePhoto}, {'showSubmit' : saveLicense}, {'loading' : loadingLicense}]"
          @submit.prevent="submitHandler"
        >
          <label class="form__label form__label--text">
            <div class="form__caption">License number</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                type="text"
                v-model="licenseNumber"
                placeholder="Enter number"
                @input="saveLicense = true"
                :disabled="loadingLicense"
              />
              <span
                v-if="$v.licenseNumber.$dirty && !$v.licenseNumber.required"
                class="form__field-invalid form__field-invalid--right-side"
              >
                This field is required
              </span>
            </div>
          </label>
          <label class="form__label form__label--text">
            <div class="form__caption">Expiration date</div>
            <div class="form__field-wrapper">
              <a-date-picker
                class="form__field js-gmi-datepicker"
                placeholder="click to change"
                :showToday="false"
                :allowClear="false"
                v-model="licenseDate"
                :format="dateFormat"
                :getCalendarContainer="getPickerContainer"
                @change="saveLicense = true"
                :disabled="loadingLicense"
              />
              <span
                v-if="$v.licenseDate.$dirty && !$v.licenseDate.required"
                class="form__field-invalid form__field-invalid--right-side"
              >
                This field is required
              </span>
            </div>
          </label>
          <div class="form__label form__label--file js-file-field">
            <div class="form__caption">Uploaded document</div>
            <label class="form__file">
              <div class="form__file-button-wr js-file-button">
                <button class="form__file-button" type="button">
                  <span class="form__file-button-icon"><SvgIcon name="cloud"/></span>
                  <span class="form__file-button-text">Upload photo</span>
                </button>
              </div>
              <div class="form__file-preview-wr js-file-preview">
                <div class="form__file-preview">
                  <span class="form__file-preview-remove js-file-remove" @click="showModal('licensePhoto')">
                    <SvgIcon name="close"/>
                  </span>
                  <div class="form__file-preview-icon">
                    <SvgIcon name="file"/>
                  </div>
                  <div class="form__file-preview-caption">
                    <div class="form__file-preview-title js-file-caption">{{license.title}}</div>
                    <div class="form__file-preview-size js-file-size">{{license.size}}MB</div>
                  </div>
                </div>
              </div>
              <input
                class="form__field"
                type="file"
                ref="licensePhoto"
                value="Upload photo"
                accept="image/*"
                @change="getFileParams"
                :disabled="loadingLicense"
              />
            </label>
          </div>
          <div class="form__label form__label--button">
            <Spinner/>
            <button class="form__button" :disabled="loadingLicense">
              <span class="text">Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="passIdDashboard__section js-acc-item-mobile">
      <div class="passIdDashboard__title js-acc-title-mobile" @click="toggleContent">
        <span class="text">Passport</span>
        <span class="icon"><SvgIcon name="down-arrow"/></span>
      </div>
      <div class="passIdDashboard__form js-acc-content-mobile">
        <!-- add class "uploadedDoc" if documents have already been uploaded -->
        <form
          class="form form--passId js-ajaxForm whitePicker js-wrap-picker-passport"
          :class="[{'uploadedDoc' : passportPhoto}, {'showSubmit' : savePassport}, {'loading' : loadingPassport}]"
          @submit.prevent="submitHandlerPassport"
        >
          <label class="form__label form__label--text">
            <div class="form__caption">Passport number</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                type="text"
                v-model="passportNumber"
                placeholder="Enter number"
                @input="savePassport = true"
                :disabled="loadingPassport"
              />
              <span
                v-if="$v.passportNumber.$dirty && !$v.passportNumber.required"
                class="form__field-invalid form__field-invalid--right-side"
              >
                This field is required
              </span>
            </div>
          </label>
          <label class="form__label form__label--text">
            <div class="form__caption">Expiration date</div>
            <div class="form__field-wrapper">
              <a-date-picker
                class="form__field js-gmi-datepicker"
                placeholder="click to change"
                :showToday="false"
                :allowClear="false"
                v-model="passportDate"
                :format="dateFormat"
                :getCalendarContainer="getPickerContainerPassport"
                @change="savePassport = true"
                :disabled="loadingPassport"
              />
              <span
                v-if="$v.passportDate.$dirty && !$v.passportDate.required"
                class="form__field-invalid form__field-invalid--right-side"
              >
                This field is required
              </span>
            </div>
          </label>
          <div class="form__label form__label--file js-file-field">
            <div class="form__caption">Uploaded document</div>
            <label class="form__file">
              <div
                class="form__file-button-wr js-file-button-passport"
              >
                <button class="form__file-button" type="button">
                  <span class="form__file-button-icon"><SvgIcon name="cloud"/></span>
                  <span class="form__file-button-text">Upload photo</span>
                </button>
              </div>
              <div
                class="form__file-preview-wr js-file-preview-passport"
              >
                <div class="form__file-preview">
                  <span class="form__file-preview-remove js-file-remove" @click="showModal('passportPhoto')">
                    <SvgIcon name="close"/>
                  </span>
                  <div class="form__file-preview-icon">
                    <SvgIcon name="file"/>
                  </div>
                  <div class="form__file-preview-caption">
                    <div class="form__file-preview-title js-file-caption">{{passport.title}}</div>
                    <div class="form__file-preview-size js-file-size">{{passport.size}}MB</div>
                  </div>
                </div>
              </div>
              <input
                class="form__field"
                type="file"
                ref="passportPhoto"
                value="Upload photo"
                accept="image/*"
                @change="getFileParamsPassport"
                :disabled="loadingPassport"
              />
            </label>
          </div>
          <div class="form__label form__label--button">
            <Spinner/>
            <button class="form__button" :disabled="loadingPassport">
              <span class="text">Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="passIdDashboard__section js-acc-item-mobile">
      <div class="passIdDashboard__title js-acc-title-mobile" @click="toggleContent">
        <span class="text">Birth certificate</span>
        <span class="icon"><SvgIcon name="down-arrow"/></span>
      </div>
      <div class="passIdDashboard__form js-acc-content-mobile">
        <!-- add class "uploadedDoc" if documents have already been uploaded -->
        <form
          class="form form--passId js-ajaxForm"
          :class="[{'uploadedDoc' : birthPhoto}, {'showSubmit' : saveBirth}, {'loading' : loadingBirth}]"
          data-href="/"
          data-action="driversLicense"
          id="birthCertificateForm"
          @submit.prevent="submitHandlerBirth"
        >
          <div class="form__label form__label--file js-file-field">
            <div class="form__caption">Uploaded document</div>
            <label class="form__file">
              <div
                class="form__file-button-wr js-file-button-birth"
              >
                <button class="form__file-button" type="button">
                  <span class="form__file-button-icon"><SvgIcon name="cloud"/></span>
                  <span class="form__file-button-text">Upload photo</span>
                </button>
              </div>
              <div
                class="form__file-preview-wr js-file-preview-birth"
              >
                <div class="form__file-preview">
                  <span class="form__file-preview-remove js-file-remove" @click="showModal('birthPhoto')">
                    <SvgIcon name="close"/>
                  </span>
                  <div class="form__file-preview-icon">
                    <SvgIcon name="file"/>
                  </div>
                  <div class="form__file-preview-caption">
                    <div class="form__file-preview-title js-file-caption">{{birth.title}}</div>
                    <div class="form__file-preview-size js-file-size">{{birth.size}}MB</div>
                  </div>
                </div>
              </div>
              <input
                class="form__field"
                type="file"
                ref="birthPhoto"
                value="Upload photo"
                accept="image/*"
                @change="getFileParamsBirth"
                :disabled="loadingBirth"
              />
            </label>
          </div>
          <div class="form__label form__label--button">
            <Spinner/>
            <button class="form__button" :disabled="loadingBirth">
              <span class="text">Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <QuestionPopup
      :name="'QuestionPopupPassId'"
      :title="'The document will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import { required } from 'vuelidate/lib/validators'
  import './PassengerId.styl'

  export default {
    name: 'PassengerId',
    data: () => ({
      licenseNumber: null,
      licenseDate: null,
      licensePhoto: false,
      license: {
        title: '',
        size: '',
      },
      licensePhotoLoaded: false,
      saveLicense: false,
      loadingLicense: false,
      passportNumber: null,
      passportDate: null,
      passportPhoto: false,
      passport: {
        title: '',
        size: '',
      },
      passportPhotoLoaded: false,
      savePassport: false,
      loadingPassport: false,
      birthPhoto: false,
      birth: {
        title: '',
        size: '',
      },
      birthPhotoLoaded: false,
      saveBirth: false,
      loadingBirth: false,
      activeFileForRemove: null,
      dateFormat: 'MM/DD/YYYY',
    }),
    validations: {
      licenseNumber: { required },
      licenseDate: { required },
      passportNumber: { required },
      passportDate: { required },
    },
    mounted () {
      document.querySelectorAll('.form__file').forEach((item) => {
        setTimeout(() => {
          item.classList.add('visible')
        }, 500)
      })

      if (this.licensePhotoLoaded) {
        this.$root.animations.fadeToggle(document.querySelector('.js-file-button'))
        this.$root.animations.fadeToggle(document.querySelector('.js-file-preview'))
      }

      if (this.passportPhotoLoaded) {
        this.$root.animations.fadeToggle(document.querySelector('.js-file-button-passport'))
        this.$root.animations.fadeToggle(document.querySelector('.js-file-preview-passport'))
      }

      if (this.birthPhotoLoaded) {
        this.$root.animations.fadeToggle(document.querySelector('.js-file-button-birth'))
        this.$root.animations.fadeToggle(document.querySelector('.js-file-preview-birth'))
      }
    },
    methods: {
      getPickerContainer (trigger) {
        return document.querySelector('.js-wrap-picker-license')
      },
      getFileParams () {
        const button = document.querySelector('.js-file-button')
        const preview = document.querySelector('.js-file-preview')

        if (!this.licensePhotoLoaded) {
          this.$root.animations.fadeToggle(button)
          setTimeout(() => {
            this.$root.animations.fadeToggle(preview)
            this.saveLicense = true
          }, 500)
          this.license.title = this.$refs.licensePhoto.files[0].name
          this.license.size = ((this.$refs.licensePhoto.files[0].size) / 1000000).toFixed(2)
          this.licensePhotoLoaded = true
        }
      },
      async submitHandler () {
        if (
          this.$v.licenseNumber.$invalid ||
          this.$v.licenseDate.$invalid
        ) {
          this.$v.licenseNumber.$touch()
          this.$v.licenseDate.$touch()
          return false
        }

        const data = {
          licenseNumber: this.licenseNumber,
          licenseDate: this.licenseDate,
          license: this.$refs.licensePhoto.files[0],
        }

        await this.$store.dispatch('addDriverLicense', data)

        this.loadingLicense = true
      },
      getPickerContainerPassport () {
        return document.querySelector('.js-wrap-picker-passport')
      },
      getFileParamsPassport () {
        const button = document.querySelector('.js-file-button-passport')
        const preview = document.querySelector('.js-file-preview-passport')

        if (!this.passportPhotoLoaded) {
          this.$root.animations.fadeToggle(button)
          setTimeout(() => {
            this.$root.animations.fadeToggle(preview)
            this.savePassport = true
          }, 500)
          this.passport.title = this.$refs.passportPhoto.files[0].name
          this.passport.size = ((this.$refs.passportPhoto.files[0].size) / 1000000).toFixed(2)
          this.passportPhotoLoaded = true
        }
      },
      async submitHandlerPassport () {
        if (
          this.$v.passportNumber.$invalid ||
          this.$v.passportDate.$invalid
        ) {
          this.$v.passportNumber.$touch()
          this.$v.passportDate.$touch()
          return false
        }

        const data = {
          passportNumber: this.passportNumber,
          passportDate: this.passportDate,
          passport: this.$refs.passportPhoto.files[0],
        }
        console.log(data)

        await this.$store.dispatch('addPassport', data)

        this.loadingPassport = true
      },
      getFileParamsBirth () {
        const button = document.querySelector('.js-file-button-birth')
        const preview = document.querySelector('.js-file-preview-birth')

        if (!this.birthPhotoLoaded) {
          this.$root.animations.fadeToggle(button)
          setTimeout(() => {
            this.$root.animations.fadeToggle(preview)
            this.saveBirth = true
          }, 500)
          this.birth.title = this.$refs.birthPhoto.files[0].name
          this.birth.size = ((this.$refs.birthPhoto.files[0].size) / 1000000).toFixed(2)
          this.birthPhotoLoaded = true
        }
      },
      async submitHandlerBirth () {
        const data = {
          birth: this.$refs.birthPhoto.files[0],
        }

        console.log(data)
        await this.$store.dispatch('addBirthCertificate', data)
        this.loadingBirth = true
      },
      showModal (type) {
        this.$modal.show('QuestionPopupPassId')
        this.activeFileForRemove = type
      },
      remove () {
        switch (this.activeFileForRemove) {
          case 'licensePhoto':
            this.removeLicensePhoto()
            break
          case 'passportPhoto':
            this.removePassportPhoto()
            break
          case 'birthPhoto':
            this.removeBirthPhoto()
            break
          default:
            return false
        }
      },
      removeLicensePhoto () {
        this.$refs.licensePhoto.value = ''
        this.$root.animations.fadeToggle(document.querySelector('.js-file-preview'))
        setTimeout(() => {
          this.$root.animations.fadeToggle(document.querySelector('.js-file-button'))
        }, 500)
        this.licensePhotoLoaded = false
      },
      removePassportPhoto () {
        this.$refs.passportPhoto.value = ''
        this.$root.animations.fadeToggle(document.querySelector('.js-file-preview-passport'))
        setTimeout(() => {
          this.$root.animations.fadeToggle(document.querySelector('.js-file-button-passport'))
        }, 500)
        this.passportPhotoLoaded = false
      },
      removeBirthPhoto () {
        this.$refs.birthPhoto.value = ''
        this.$root.animations.fadeToggle(document.querySelector('.js-file-preview-birth'))
        setTimeout(() => {
          this.$root.animations.fadeToggle(document.querySelector('.js-file-button-birth'))
        }, 500)
        this.birthPhotoLoaded = false
      },
      toggleContent (event) {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const item = event.currentTarget.closest('.js-acc-item-mobile')
          const content = item.querySelector('.js-acc-content-mobile')

          item.classList.toggle('mutation')
          this.$root.animations.slideToggle(content)
        }
      },
    },
    components: {
      SvgIcon,
      Spinner,
      QuestionPopup,
    },
  }
</script>
